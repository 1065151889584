<template>
  <v-list-item dense three-line v-if="validSub1 || validSub2 || validSub3 || validSub4 || validSub5 || validSub6">
    <v-list-item-content>
      <v-list-item-title
        class="primary--text subtitle-2 font-weight-bold my-2"
        data-test="title"
        >{{ title }}</v-list-item-title
      >
      <v-list-item-subtitle
        v-if="validSub1"
        class="font-weight-bold my-1 ml-5"
        :class="`${color}--text`"
        data-test="sub1"
      >
        {{ subtitle_1 }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="validSub2"
        class="font-weight-bold my-1 ml-5"
        :class="`${color}--text`"
        data-test="sub2"
      >
        {{ subtitle_2 }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="validSub3"
        class="font-weight-bold my-1 ml-5"
        :class="`${color}--text`"
        data-test="sub3"
      >
        {{ subtitle_3 }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="validSub4"
        class="font-weight-bold my-1 ml-5"
        :class="`${color}--text`"
        data-test="sub4"
      >
        {{ subtitle_4 }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="validSub5"
        class="font-weight-bold my-1 ml-5"
        :class="`${color}--text`"
        data-test="sub5"
      >
        {{ subtitle_5 }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="validSub6"
        class="font-weight-bold my-1 ml-5"
        :class="`${color}--text`"
        data-test="sub6"
      >
        {{ subtitle_6 }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: "MissingHeaderNotes",
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle_1: {
      type: String,
      default: null,
    },
    subtitle_2: {
      type: String,
      default: null,
    },
    subtitle_3: {
      type: String,
      default: null,
    },
    subtitle_4: {
      type: String,
      default: null,
    },
    subtitle_5: {
      type: String,
      default: null,
    },
    subtitle_6: {
      type: String,
      default: null,
    },
    validSub1: {
      type: Boolean,
      default: null,
    },
    validSub2: {
      type: Boolean,
      default: null,
    },
    validSub3: {
      type: Boolean,
      default: null,
    },
    validSub4: {
      type: Boolean,
      default: null,
    },
    validSub5: {
      type: Boolean,
      default: null,
    },
    validSub6: {
      type: Boolean,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
};
</script>
